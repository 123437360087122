import VocabEstimator from './components/VocabEstimator';

const App = () => {
  
  return (
    <div className="App">
        <VocabEstimator/>
    </div>
  );
}

export default App;
