import Form from './Form';


const VocabEstimator = () => {
    

    return (
        <div className="VocabEstimatorForm">
            <Form />
        </div>
      );
}

export default VocabEstimator;